import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

export const Load = ({ children }) => {
  return (
    <ScrollAnimation animateIn='animate__fadeIn' delay={100}>
      {children}
    </ScrollAnimation>
  )
}
export const Zoom = ({ children }) => {
  return (
    <ScrollAnimation animateIn='animate__zoomIn' offset={0} animateOnce={true}>
      {children}
    </ScrollAnimation>
  )
}
export const Fade = ({ children, delay = 0 }) => {
  return (
    <ScrollAnimation
      animateIn='animate__fadeIn'
      offset={0}
      animateOnce={true}
      delay={delay}
    >
      {children}
    </ScrollAnimation>
  )
}
export const FadeDown = ({ children, delay = 0 }) => {
  return (
    <ScrollAnimation
      animateIn='animate__fadeInDown'
      offset={0}
      animateOnce={true}
      delay={delay}
    >
      {children}
    </ScrollAnimation>
  )
}
export const FadeInRight = ({ children, delay = 0 }) => {
  return (
    <ScrollAnimation
      animateIn='animate__fadeInRight'
      offset={0}
      animateOnce={true}
      delay={delay}
    >
      {children}
    </ScrollAnimation>
  )
}
export const Scroll = ({ children, delay = 0 }) => {
  return (
    <ScrollAnimation
      animateIn='animate__fadeInUp'
      offset={0}
      animateOnce={true}
      delay={delay}
    >
      {children}
    </ScrollAnimation>
  )
}
